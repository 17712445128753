'use client';

import React, { useState } from 'react';
import { Box, Button, Typography, Chip } from '@mui/material';

interface VibeModalProps {
    selectedSpaces: string[];
    spaceTypes: string[];
    locationSpaces?: string[];
    venues: {
        Attributes: { [key: string]: boolean };
    }[];
    onSpaceTypeChange: (type: string[]) => void;
    onClose?: () => void;
}

const MobileVibeModal: React.FC<VibeModalProps> = ({
    selectedSpaces,
    spaceTypes,
    locationSpaces,
    venues,
    onSpaceTypeChange,
}) => {
    const [tempSelectedSpaces, setTempSelectedSpaces] = useState<string[]>(selectedSpaces);

    const availableVenueCount = venues.filter(venue =>
        tempSelectedSpaces.every(space =>
            locationSpaces?.includes(space) ?
                tempSelectedSpaces.filter(s => locationSpaces?.includes(s)).some(loc => venue.Attributes[loc]) :
                venue.Attributes[space]
        )
    ).length;

    const isAttributeSelectable = (type: string) => {
        if (tempSelectedSpaces.includes(type)) return true;

        if (!locationSpaces?.includes(type)) {
            return venues.some(venue => {
                const newSpaces = [...tempSelectedSpaces, type];
                return newSpaces.every(space =>
                    locationSpaces?.includes(space)
                        ? newSpaces.filter(s => locationSpaces?.includes(s)).some(loc => venue.Attributes[loc])
                        : venue.Attributes[space]
                );
            });
        }

        return venues.some(venue => {
            const nonLocationSelections = tempSelectedSpaces.filter(s => !locationSpaces.includes(s));
            return nonLocationSelections.every(space => venue.Attributes[space]) && venue.Attributes[type];
        });
    };

    const handleSpaceTypeClick = (type: string) => {
        if (!isAttributeSelectable(type) && !tempSelectedSpaces.includes(type)) return;

        const newSpaces = tempSelectedSpaces.includes(type)
            ? tempSelectedSpaces.filter(t => t !== type)
            : [...tempSelectedSpaces, type];

        setTempSelectedSpaces(newSpaces);
        onSpaceTypeChange(newSpaces);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Typography
                variant="body2"
                sx={{
                    mb: 1.5,
                    color: 'rgba(255,255,255,0.7)',
                    fontSize: '0.875rem'
                }}
            >
                {availableVenueCount} venues available
            </Typography>

            <Box sx={{ mb: 1.5 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 0.75,
                        maxHeight: '180px',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': { width: '3px' },
                        '&::-webkit-scrollbar-track': { background: 'rgba(224, 177, 241, 0.05)' },
                        '&::-webkit-scrollbar-thumb': { background: 'rgba(224, 177, 241, 0.3)' },
                    }}
                >
                    {spaceTypes.filter(type => !locationSpaces?.includes(type)).map((type) => {
                        const isSelectable = isAttributeSelectable(type);
                        const isSelected = tempSelectedSpaces.includes(type);
                        return (
                            <Chip
                                key={type}
                                label={type}
                                onClick={() => handleSpaceTypeClick(type)}
                                disabled={!isSelectable && !isSelected}
                                size="small"
                                sx={{
                                    height: '28px',
                                    backgroundColor: tempSelectedSpaces.includes(type)
                                        ? '#E0B1F1'
                                        : 'rgba(224, 177, 241, 0.1)',
                                    border: `1px solid ${tempSelectedSpaces.includes(type)
                                        ? '#E0B1F1'
                                        : 'rgba(224, 177, 241, 0.4)'}`,
                                    color: tempSelectedSpaces.includes(type)
                                        ? '#2c2c54'
                                        : (!isAttributeSelectable(type) ? 'rgba(255,255,255,0.4)' : '#fff'),
                                    fontSize: '0.8rem',
                                    '&:hover': {
                                        backgroundColor: tempSelectedSpaces.includes(type)
                                            ? '#D8A8E8'
                                            : (isAttributeSelectable(type) ? 'rgba(224, 177, 241, 0.2)' : 'rgba(224, 177, 241, 0.1)'),
                                    },
                                    opacity: (!isAttributeSelectable(type) && !tempSelectedSpaces.includes(type)) ? 0.5 : 1,
                                    cursor: (!isAttributeSelectable(type) && !tempSelectedSpaces.includes(type)) ? 'not-allowed' : 'pointer',
                                    transition: 'all 0.2s ease',
                                }}
                            />
                        );
                    })}
                </Box>
            </Box>

            {locationSpaces && (
                <Box sx={{ mt: 1 }}>
                    <Typography
                        sx={{
                            fontSize: '0.75rem',
                            color: 'rgba(255,255,255,0.7)',
                            mb: 0.75
                        }}
                    >
                        Locations (You can select multiple)
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.75,
                            maxHeight: '120px',
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': { width: '3px' },
                            '&::-webkit-scrollbar-track': { background: 'rgba(224, 177, 241, 0.05)' },
                            '&::-webkit-scrollbar-thumb': { background: 'rgba(224, 177, 241, 0.3)' },
                        }}
                    >
                        {locationSpaces.map((type) => {
                            const isSelected = tempSelectedSpaces.includes(type);
                            return (
                                <Chip
                                    key={type}
                                    label={type}
                                    onClick={() => handleSpaceTypeClick(type)}
                                    size="small"
                                    sx={{
                                        height: '28px',
                                        backgroundColor: tempSelectedSpaces.includes(type)
                                            ? '#E0B1F1'
                                            : 'rgba(224, 177, 241, 0.1)',
                                        border: `1px solid ${tempSelectedSpaces.includes(type)
                                            ? '#E0B1F1'
                                            : 'rgba(224, 177, 241, 0.4)'}`,
                                        color: tempSelectedSpaces.includes(type)
                                            ? '#2c2c54'
                                            : (!isAttributeSelectable(type) ? 'rgba(255,255,255,0.4)' : '#fff'),
                                        fontSize: '0.8rem',
                                        '&:hover': {
                                            backgroundColor: tempSelectedSpaces.includes(type)
                                                ? '#D8A8E8'
                                                : (isAttributeSelectable(type) ? 'rgba(224, 177, 241, 0.2)' : 'rgba(224, 177, 241, 0.1)'),
                                        },
                                        opacity: (!isAttributeSelectable(type) && !tempSelectedSpaces.includes(type)) ? 0.5 : 1,
                                        cursor: (!isAttributeSelectable(type) && !tempSelectedSpaces.includes(type)) ? 'not-allowed' : 'pointer',
                                        transition: 'all 0.2s ease',
                                    }}
                                />
                            );
                        })}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default MobileVibeModal;