import React from 'react';
import { Box, Typography } from '@mui/material';
import Link from 'next/link';
import DiscoBall from './disco_ball';
import LinkWithLoading from './link_with_loading';
import SmallLogo from './small_logo';

export function OnlyLogoNavBar() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: 2,
            padding: '16px 24px',
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                position: 'relative',
                mb: 3

            }}>
                <SmallLogo />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    marginTop: '16px',
                }}
            />
        </Box>
    );
}

export default OnlyLogoNavBar;
