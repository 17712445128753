'use client';

import React, { useEffect, useState, useMemo, useCallback, memo } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Rating,
  Skeleton,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Venue, VenueBookedTimeSlot, DayOfWeek } from '../../../shared/models';
import { getAttributeIcons } from '../lib/firebase/metadata';
import LinkWithLoading from './link_with_loading';
import Attributes from './attributes';
import ImageCarouselSwiper from './image_carousel_swiper';
import { getAppropriateSectionID } from '../lib/util';

interface MobileVerticalFeaturedCardProps {
  venue: Venue;
  venueBookedTimeSlots: Record<string, VenueBookedTimeSlot[]>;
  onHover: (venueId: string | null) => void;
  onCenterMap?: (venue: Venue) => void;
  currentDate?: Date;
  people?: number;
  startTime?: string;
  venuePrices?: Record<string, number> | null;
  onClick?: () => void;
}

// Internal helper components to optimize rendering
const VenuePricing = memo(({
  venue,
  people,
  venuePrices,
}: {
  venue: Venue;
  people: number;
  venuePrices?: Record<string, number> | null;
}) => {
  // Calculate pricing info
  const pricing = useMemo(() => {
    // Get the price from the backend data if available
    const pricePerPerson = venuePrices && venuePrices[venue.ID] ? venuePrices[venue.ID] : 0;

    // Find appropriate section for capacity info
    const sectionID = getAppropriateSectionID(venue, people);
    const section = sectionID ? venue.Sections[sectionID] : null;
    const pricingRate = sectionID && venue.PricingRatesPerSection ? venue.PricingRatesPerSection[sectionID] : null;

    return {
      price: pricePerPerson * people,
      pricePerPerson: pricePerPerson,
      minimumBookingBlocks: pricingRate?.MinimumBookingDuration || 2,
      maxCapacity: section?.Capacity || 0,
      isMinimumSpend: pricingRate?.TowardsMinimumSpend || false
    };
  }, [venue, people, venuePrices]);

  // Check if this venue has no minimum spend option for this group size and section
  const hasNoMinimumSpendOption = useMemo(() => {
    // Check if any section has no minimum spend option and can fit the party
    const hasAnyNoMinimumSpendSection = Object.entries(venue.PricingRatesPerSection).some(
      ([sectionId, rate]) =>
        rate?.IsNoMinimumSpendOption === true &&
        venue.Sections[sectionId]?.Capacity >= people
    );

    return hasAnyNoMinimumSpendSection;
  }, [venue, people]);

  return (
    <div className="price-section font-sans leading-tight mt-1 md:mt-0.5">
      {hasNoMinimumSpendOption ? (
        <>
          <div className="mb-1">
            <span className="inline-block bg-emerald-500/20 border border-emerald-400/30 px-2.5 py-1 rounded-md text-white text-xs font-semibold">
              <span className="text-emerald-300">✓</span> No minimum spend
            </span>
          </div>
          <div className="flex items-baseline">
            <span className="text-emerald-400 text-[15px] md:text-[15px] font-semibold mr-1">FREE</span>
            <span className="text-gray-200 text-[14px] md:text-[13px]">per person · {pricing.minimumBookingBlocks} hours</span>
          </div>
          {people &&
            <div className="flex items-baseline mt-0.5 md:mt-0.5">
              <span className="text-opacity-70 text-[13px] md:text-[12px]">Total: </span>
              <span className="text-emerald-400 text-[15px] md:text-[14px] font-medium ml-1">Free</span>
            </div>
          }
        </>
      ) : (
        <>
          <div className="flex items-baseline">
            <span className="text-[15px] md:text-[15px] font-semibold mr-1 price-value price-per-person" data-venue-id={venue.ID} data-price-value={pricing.pricePerPerson}>${pricing.pricePerPerson.toLocaleString()}</span>
            <span className="text-gray-200 text-[14px] md:text-[13px]">/person · {pricing.minimumBookingBlocks} hours</span>
            {people > pricing.maxCapacity &&
              <span className="text-[13px] md:text-[12px] text-opacity-70 ml-1">({pricing.maxCapacity} people)</span>
            }
          </div>
          {people &&
            <div className="flex items-baseline mt-0.5 md:mt-0.5">
              <span className="text-opacity-70 text-[13px] md:text-[12px]">Total: </span>
              <span className="text-white text-[15px] md:text-[14px] font-medium ml-1 price-value price-total" data-venue-id={venue.ID} data-price-value={Math.round(pricing.price)}>${Math.round(pricing.price).toLocaleString()}</span>
              {pricing.isMinimumSpend && <span className="ml-1 text-[13px] md:text-[12px] text-opacity-70">(minimum spend)</span>}
            </div>
          }
        </>
      )}
    </div>
  );
});

VenuePricing.displayName = 'VenuePricing';

// Enhance the skeleton component
export const MobileVerticalFeaturedCardSkeleton: React.FC = () => {
  return (
    <div
      className="venue-card-skeleton text-white pl-3 md:pl-0"
      style={{
        cursor: 'default',
        transform: 'translateZ(0)',
      }}
    >
      {/* Image Section Skeleton with shimmer effect */}
      <div className="relative w-full pb-[110%] md:pb-[100%] mb-3 md:mb-2">
        <div className="absolute inset-0 rounded-2xl overflow-hidden bg-gradient-to-r from-white/5 via-white/10 to-white/5 animate-shimmer"
          style={{ backgroundSize: '200% 100%', animationDuration: '1.5s' }} />
      </div>

      {/* Compact content section to match Airbnb style */}
      <div className="text-content space-y-1 md:space-y-0.5">
        {/* Header with Title and Rating */}
        <div className="flex justify-between items-center mb-1">
          <div className="h-[24px] md:h-[22px] bg-gradient-to-r from-white/5 via-white/10 to-white/5 rounded w-3/4 animate-shimmer"
            style={{ backgroundSize: '200% 100%', animationDuration: '1.5s' }} />
          <div className="flex items-center gap-1">
            <div className="h-5 w-5 md:h-4 md:w-4 rounded-full bg-gradient-to-r from-gray-500/20 via-gray-400/20 to-gray-500/20 animate-shimmer"
              style={{ backgroundSize: '200% 100%', animationDuration: '1.5s' }} />
          </div>
        </div>

        {/* Attributes Skeleton */}
        <div className="mb-0.5">
          <div className="h-[16px] md:h-[15px] bg-gradient-to-r from-white/5 via-white/10 to-white/5 rounded w-2/3 animate-shimmer"
            style={{ backgroundSize: '200% 100%', animationDuration: '1.5s' }} />
        </div>

        {/* Availability Skeleton */}
        <div className="mb-0.5">
          <div className="h-[16px] md:h-[15px] bg-gradient-to-r from-white/5 via-white/10 to-white/5 rounded w-5/6 animate-shimmer"
            style={{ backgroundSize: '200% 100%', animationDuration: '1.5s' }} />
        </div>

        {/* Price Skeleton */}
        <div className="mt-1 md:mt-0.5">
          <div className="flex items-center mb-0.5">
            <div className="h-[16px] md:h-[15px] bg-gradient-to-r from-white/5 via-white/10 to-white/5 rounded w-1/4 animate-shimmer mr-1"
              style={{ backgroundSize: '200% 100%', animationDuration: '1.5s' }} />
            <div className="h-[15px] md:h-[14px] bg-gradient-to-r from-white/5 via-white/10 to-white/5 rounded w-1/3 animate-shimmer"
              style={{ backgroundSize: '200% 100%', animationDuration: '1.5s' }} />
          </div>
          <div className="flex items-center">
            <div className="h-[15px] md:h-[14px] bg-gradient-to-r from-white/5 via-white/10 to-white/5 rounded w-1/5 animate-shimmer mr-1"
              style={{ backgroundSize: '200% 100%', animationDuration: '1.5s' }} />
            <div className="h-[16px] md:h-[15px] bg-gradient-to-r from-white/5 via-white/10 to-white/5 rounded w-1/6 animate-shimmer"
              style={{ backgroundSize: '200% 100%', animationDuration: '1.5s' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

// Add the SkeletonGrid component after the MobileVerticalFeaturedCardSkeleton component
// Skeleton Grid Component to display multiple skeletons
interface SkeletonGridProps {
  count?: number;
  className?: string;
}

export const MobileVerticalFeaturedCardSkeletonGrid: React.FC<SkeletonGridProps> = ({ count = 3, className = '' }) => {
  return (
    <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 md:gap-5 ${className}`}>
      {Array.from({ length: count }).map((_, index) => (
        <MobileVerticalFeaturedCardSkeleton key={`skeleton-${index}`} />
      ))}
    </div>
  );
};

// Main component with memoization applied
export const MobileVerticalFeaturedCard = memo(({
  venue,
  venueBookedTimeSlots,
  onHover,
  onCenterMap,
  currentDate = new Date(),
  people = 10,
  startTime = '19:00',
  venuePrices,
  onClick
}: MobileVerticalFeaturedCardProps) => {
  const [attributeIcons, setAttributeIcons] = useState<Record<string, string>>({});

  // Memoize expensive calculations
  const nextAvailabilities = useMemo(() => {
    return getNextAvailabilities(venue, currentDate, venueBookedTimeSlots);
  }, [venue, currentDate, venueBookedTimeSlots]);

  const firstAvailabilityDate = useMemo(() => {
    return getFirstAvailabilityDate(venue, currentDate);
  }, [venue, currentDate]);

  // Memoize image preparation
  const imageData = useMemo(() => {
    return venue.PhotoUrls
      ? venue.PhotoUrls.map((url, index) => ({
        src: url,
        key: `${venue.ID}-${url}-${index}`,
      }))
      : [];
  }, [venue.PhotoUrls, venue.ID]);

  useEffect(() => {
    let isMounted = true;
    const fetchAttributeIcons = async () => {
      try {
        const icons = await getAttributeIcons();
        if (isMounted) {
          setAttributeIcons(icons);
        }
      } catch (error) {
        console.error('Error fetching attribute icons:', error);
      }
    };
    fetchAttributeIcons();

    return () => { isMounted = false; };
  }, []);

  // Make event handlers stable references with useCallback
  const handleMouseEnter = useCallback(() => {
    if (onHover) {
      onHover(venue.ID);
    }
  }, [onHover, venue.ID]);

  const handleMouseLeave = useCallback(() => {
    if (onHover) {
      onHover(null);
    }
  }, [onHover]);

  const handleCenterMap = useCallback(() => {
    if (onCenterMap) {
      onCenterMap(venue);
    }
  }, [onCenterMap, venue]);

  const handleClick = useCallback(() => {
    // Call the onClick handler if provided
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  // Link URL creation is memoized to avoid string concatenation on each render
  const linkUrl = useMemo(() => {
    return `/venue/${venue.ID}?date=${currentDate?.toISOString()}&time=${startTime || ''}&size=${people || ''}`;
  }, [venue.ID, currentDate, startTime, people]);

  return (
    <LinkWithLoading href={linkUrl} passHref target="_blank" rel="noopener noreferrer">
      <div
        className="venue-card hover:scale-[1.03] transition-all duration-300 text-white pl-4 pr-1.5 md:pl-0 md:pr-0"
        data-testid="venue-card"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          cursor: 'pointer',
          transform: 'translateZ(0)',
        }}
        onClick={handleClick}
      >
        {/* Image Section */}
        <div className="relative w-full pb-[110%] md:pb-[100%] mb-3 md:mb-2">
          <div className="absolute inset-0 rounded-2xl overflow-hidden">
            <ImageCarouselSwiper images={imageData} />
          </div>
        </div>

        {/* Content Section - Compact version similar to Airbnb */}
        <div className="text-content space-y-0.5">
          {/* Header with Title and Rating */}
          <div className="flex justify-between items-center mb-1 md:mb-0.5">
            <h3 className="text-[22px] md:text-[20px] leading-[1.1] truncate flex-1 pr-2 font-['Thick']">{venue.Name}</h3>
            <span className="flex items-center text-sm md:text-sm font-medium">
              <span className="text-gray-300 mr-1 text-lg md:text-base">★</span>
              <span className="font-sans text-gray-300">{venue.Rating}</span>
            </span>
          </div>

          {/* Attributes and Availability in a single compact block */}
          <div className="text-opacity-90 text-[13px] md:text-[13px] leading-tight font-sans">
            <Attributes attributes={venue.Attributes} attributeIcons={attributeIcons} maxAttributes={2} muted={false} />
          </div>
          <div className="text-gray-400 text-[13px] md:text-[13px] leading-tight font-sans">
            <span>Available starting&nbsp;</span>
            {nextAvailabilities.map((date, index) => (
              <React.Fragment key={date}>
                {index > 0 && <span>,&nbsp;</span>}
                <span className="font-semibold text-white">{date}</span>
              </React.Fragment>
            ))}
          </div>

          {/* Pricing Information */}
          <VenuePricing
            venue={venue}
            people={people}
            venuePrices={venuePrices}
          />
        </div>
      </div>
    </LinkWithLoading>
  );
});

MobileVerticalFeaturedCard.displayName = 'MobileVerticalFeaturedCard';

// Helper functions extracted outside the component
function getNextAvailabilities(venue: Venue, startDate: Date, venueBookedTimeSlots: Record<string, VenueBookedTimeSlot[]>): string[] {
  const nextDays: string[] = [];

  for (let i = 0; i < 3 && nextDays.length < 4; i++) {
    const nextDate = new Date(startDate);
    nextDate.setDate(startDate.getDate() + i);
    const dayOfWeek = nextDate.toLocaleString('en-US', { weekday: 'long' }) as DayOfWeek;
    const dateString = nextDate.toDateString();
    const formattedDate = `${nextDate.getMonth() + 1}/${nextDate.getDate()}`;

    if (venue.Availability[dayOfWeek] && venue.Availability[dayOfWeek].IsOpen) {
      if (venueBookedTimeSlots && (!venueBookedTimeSlots[dateString] || venueBookedTimeSlots[dateString].length === 0)) {
        nextDays.push(formattedDate);
      }
    }
  }

  return nextDays;
}

function getFirstAvailabilityDate(venue: Venue, startDate: Date): Date {
  return Object.values(venue.Availability).some(day => day.IsOpen)
    ? new Date(startDate)
    : new Date(startDate);
}

// Memoized skeleton
export const VerticalFeaturedCardSkeleton = memo(() => {
  return (
    <Grid container spacing={2} sx={{ padding: '8px', height: '100%' }}>
      {[0, 1].map((index) => (
        <Grid item xs={12} sm={6} key={index} sx={{ mb: 2 }}>
          <Card
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              color: 'white',
              borderRadius: '16px',
              boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              margin: '4px',
            }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height={350}
              animation="wave"
              sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }}
            />
            <CardContent sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Box>
                <Skeleton
                  variant="text"
                  width="80%"
                  height={24}
                  animation="wave"
                  sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', mb: 1 }}
                />
                <Skeleton
                  variant="text"
                  width="60%"
                  height={16}
                  animation="wave"
                  sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', mb: 1 }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
});

VerticalFeaturedCardSkeleton.displayName = 'VerticalFeaturedCardSkeleton';