'use client';

import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { collection, addDoc, setDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase/app';
import { NewsLetterSubscriber } from '../../../shared/models';

// Props for the wrapper component
interface NewsletterTriggerProps {
    children: React.ReactNode;
}

// Props for the actual modal component
interface NewsletterModalProps {
    isOpen: boolean;
    onClose: () => void;
}

// The controlled modal component
const NewsletterModalContent: React.FC<NewsletterModalProps> = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        if (!email) {
            setError('Please enter your email');
            setLoading(false);
            return;
        }

        try {
            await setDoc(doc(db, 'newsletter_subscribers', email), {
                Email: email,
                Subscribed: true,
                SubscribedAt: new Date(),
                PageVisited: window.location.pathname
            } as NewsLetterSubscriber);
            setSuccess(true);
            setTimeout(() => {
                onClose();
                setSuccess(false);
                setEmail('');
            }, 2000);
        } catch (err) {
            setError('Something went wrong. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="newsletter-modal-title"
            aria-describedby="newsletter-modal-description"
            sx={{ zIndex: 4000 }}
        >
            <Box sx={{
                position: 'absolute',
                top: { xs: 0, sm: '50%' },
                left: { xs: 0, sm: '50%' },
                transform: { xs: 'none', sm: 'translate(-50%, -50%)' },
                width: { xs: '100%', sm: '95%' },
                height: { xs: '100%', sm: 'auto' },
                maxWidth: { xs: '100%', sm: 400 },
                bgcolor: '#2c2c54',
                color: 'white',
                boxShadow: 24,
                p: { xs: 3, sm: 3 },
                borderRadius: { xs: 0, sm: 3 },
                maxHeight: { xs: '100%', sm: '90vh' },
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                zIndex: 4000
            }}>
                <Typography
                    id="newsletter-modal-title"
                    variant="h6"
                    component="h2"
                    gutterBottom
                    fontFamily="Thick"
                    sx={{ mb: 2 }}
                >
                    📫 Stay Connected with Litty!
                </Typography>

                <Typography
                    sx={{
                        mb: 3,
                        color: 'rgba(255,255,255,0.7)',
                        fontFamily: 'Sans',
                        fontSize: { xs: '0.9rem', sm: '0.9rem' }
                    }}
                >
                    Get the latest venue guides and NYC nightlife updates delivered straight to your inbox.
                </Typography>

                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            mb: 2,
                            '& .MuiOutlinedInput-root': {
                                color: 'white',
                                '& fieldset': {
                                    borderColor: 'rgba(255, 255, 255, 0.3)',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(255, 255, 255, 0.5)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#E0B1F1',
                                },
                            },
                        }}
                    />

                    {error && (
                        <Typography
                            color="error"
                            sx={{ mb: 2, textAlign: 'center', fontFamily: 'Sans' }}
                        >
                            {error}
                        </Typography>
                    )}

                    {success && (
                        <Typography
                            sx={{
                                color: '#4CAF50',
                                mb: 2,
                                textAlign: 'center',
                                fontFamily: 'Sans'
                            }}
                        >
                            Successfully subscribed!
                        </Typography>
                    )}

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={loading}
                        sx={{
                            backgroundColor: '#E0B1F1',
                            color: '#2c2c54',
                            '&:hover': { backgroundColor: '#D8A8E8' },
                            px: 3,
                            fontSize: { xs: '1rem', sm: '0.9rem' }
                        }}
                    >
                        {loading ? 'Subscribing...' : 'Subscribe'}
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

// The wrapper component that manages state
const NewsletterModal: React.FC<NewsletterTriggerProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div onClick={() => setIsOpen(true)}>
                {children}
            </div>
            <NewsletterModalContent
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            />
        </>
    );
};

export default NewsletterModal;