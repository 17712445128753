'use client';

import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, Fade, IconButton, Alert, Snackbar, Checkbox, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, MenuItem, Select, FormControl, InputLabel, List, ListItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { logFirebaseEvent } from '../lib/firebase/app';
import { useRouter } from 'next/navigation';
import { calculateCheapestVenuePricing, getBackendURL, } from '../lib/util';
import SmallCustomCalendar from './small_custom_calendar';
import { CustomerInquiry, Venue } from '../../../shared/models';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InquireToVenueMatchmaker from './inquire_to_venue_matchmaker';
import { MatchMakerSession } from '../../../shared/models';
import EventIcon from '@mui/icons-material/Event';
import GroupIcon from '@mui/icons-material/Group';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { track } from '@vercel/analytics';

interface MessageThroughWebsiteProps {
    venue: Venue;
    defaultEmail?: string;
    defaultPhone?: string;
    defaultMessage?: string;
    defaultName?: string;
    defaultDate?: string;
    defaultTime?: string;
    defaultMustHaves?: string;
    defaultFlexibility?: string;
    defaultLocationPreference?: string;
    defaultVenueTypePreference?: string;
    defaultNumberOfGuests?: string;
}

interface FormErrors {
    email: string;
    phone: string;
    name: string;
    numberOfGuests: string;
    preferredDate: string;
    preferredTime: string;
}

const MessageThroughWebsite: React.FC<MessageThroughWebsiteProps> = ({
    venue,
    defaultEmail = '',
    defaultPhone = '',
    defaultMessage = '',
    defaultName = '',
    defaultDate = '',
    defaultTime = '',
    defaultMustHaves = '',
    defaultFlexibility = '',
    defaultLocationPreference = '',
    defaultVenueTypePreference = '',
    defaultNumberOfGuests = ''
}) => {
    const [open, setOpen] = useState(false);
    const [formStep, setFormStep] = useState<1 | 2 | 3>(1);
    const [email, setEmail] = useState(defaultEmail);
    const [phone, setPhone] = useState(defaultPhone);
    const [message, setMessage] = useState(defaultMessage);
    const [sending, setSending] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errors, setErrors] = useState<FormErrors>({
        email: '',
        phone: '',
        name: '',
        numberOfGuests: '',
        preferredDate: '',
        preferredTime: ''
    });
    const [name, setName] = useState(defaultName);
    const [locationPreference, setLocationPreference] = useState(defaultLocationPreference);
    const [venueTypePreference, setVenueTypePreference] = useState(defaultVenueTypePreference);
    const [interestedInSimilar, setInterestedInSimilar] = useState(true);
    const [flexibility, setFlexibility] = useState(defaultFlexibility);
    const [mustHaves, setMustHaves] = useState(defaultMustHaves);
    const [dateRequested, setDateRequested] = useState<Date | null>(defaultDate ? new Date(defaultDate) : null);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [numberOfGuests, setNumberOfGuests] = useState(defaultNumberOfGuests);
    const [modalOpenTime, setModalOpenTime] = useState<Date | null>(null);
    const router = useRouter();
    const [dateAccordionExpanded, setDateAccordionExpanded] = useState(true);
    const [consentChecked, setConsentChecked] = useState(true);
    const [openBarRequired, setOpenBarRequired] = useState(false);
    const [ownMusicRequired, setOwnMusicRequired] = useState(false);
    const [fullyPrivateRequired, setFullyPrivateRequired] = useState(false);
    const [otherRequirementsChecked, setOtherRequirementsChecked] = useState(false);
    const [showMatchmaker, setShowMatchmaker] = useState(false);
    const [existingInquiries, setExistingInquiries] = useState<MatchMakerSession[]>([]);
    const [showInquiryChoice, setShowInquiryChoice] = useState(false);
    const [showAllInquiries, setShowAllInquiries] = useState(false);
    const [sendingInquiryId, setSendingInquiryId] = useState<string | null>(null);

    useEffect(() => {
        if (defaultEmail) setEmail(defaultEmail);
        if (defaultPhone) setPhone(defaultPhone);
        if (defaultMessage) setMessage(defaultMessage);
        if (defaultName) setName(defaultName);
        if (defaultDate) setDateRequested(defaultDate ? new Date(defaultDate) : null);
        if (defaultTime) setStartTime(defaultTime.split(' - ')[0]);
        if (defaultTime) setEndTime(defaultTime.split(' - ')[1]);
        if (defaultMustHaves) setMustHaves(defaultMustHaves);
        if (defaultFlexibility) setFlexibility(defaultFlexibility);
        if (defaultLocationPreference) setLocationPreference(defaultLocationPreference);
        if (defaultVenueTypePreference) setVenueTypePreference(defaultVenueTypePreference);
        if (defaultNumberOfGuests) setNumberOfGuests(defaultNumberOfGuests);
    }, [
        defaultEmail,
        defaultPhone,
        defaultMessage,
        defaultName,
        defaultDate,
        defaultTime,
        defaultMustHaves,
        defaultFlexibility,
        defaultLocationPreference,
        defaultVenueTypePreference,
        defaultNumberOfGuests
    ]);

    useEffect(() => {
        // Check for existing inquiries in localStorage
        const sessionsStr = localStorage.getItem('matchmaker_sessions');
        if (sessionsStr) {
            const sessions = JSON.parse(sessionsStr) as MatchMakerSession[];
            if (sessions.length > 0) {
                setExistingInquiries(sessions);
            }
        }
    }, []);

    const validateEmail = (email: string) => {
        if (!email) return true;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhone = (phone: string) => {
        if (!phone) return true;
        const phoneRegex = /^\+?[\d\s-()]{10,}$/;
        return phoneRegex.test(phone);
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors: FormErrors = {
            email: '',
            phone: '',
            name: '',
            numberOfGuests: '',
            preferredDate: '',
            preferredTime: ''
        };

        if (!name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }

        // Require at least one contact method
        if (!email && !phone) {
            newErrors.email = 'Please provide either an email or phone number';
            newErrors.phone = 'Please provide either an email or phone number';
            isValid = false;
        } else {
            // Validate email if provided
            if (email && !validateEmail(email)) {
                newErrors.email = 'Please enter a valid email address';
                isValid = false;
            }

            // Validate phone if provided. Convert string to only numbers and check length.
            if (phone && phone.replace(/\D/g, '').length < 10) {
                newErrors.phone = 'Please enter a valid phone number';
                isValid = false;
            }
        }

        if (!numberOfGuests) {
            newErrors.numberOfGuests = 'Number of guests is required';
            isValid = false;
        }

        if (!dateRequested) {
            newErrors.preferredDate = 'Preferred date is required';
            isValid = false;
        }

        if (!startTime || !endTime) {
            newErrors.preferredTime = 'Preferred time is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleContinue = (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateRequiredFields()) {
            track('form_validation_failure', {
                venue_id: venue.ID,
                venue_name: venue.Name,
                step: 'event_details'
            });
            return;
        }

        track('form_step_complete', {
            venue_id: venue.ID,
            venue_name: venue.Name,
            step: 'event_details',
            guest_count: numberOfGuests,
            has_date: !!dateRequested
        });

        setFormStep(2);
    };

    const handleBack = () => {
        const currentStep = formStep;
        setFormStep(prev => (prev > 1 ? prev - 1 : 1) as 1 | 2 | 3);

        track('form_step_back', {
            venue_id: venue.ID,
            venue_name: venue.Name,
            from_step: currentStep
        });
    };

    // Separate validation for required fields only
    const validateRequiredFields = () => {
        let isValid = true;
        const newErrors: FormErrors = {
            email: '',
            phone: '',
            name: '',
            numberOfGuests: '',
            preferredDate: '',
            preferredTime: ''
        };

        if (!name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }

        if (!numberOfGuests) {
            newErrors.numberOfGuests = 'Number of guests is required';
            isValid = false;
        }

        if (!dateRequested) {
            newErrors.preferredDate = 'Preferred date is required';
            isValid = false;
        }

        if (!startTime || !endTime) {
            newErrors.preferredTime = 'Preferred time is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm()) {
            track('form_validation_failure', {
                venue_id: venue.ID,
                venue_name: venue.Name,
                step: 'confirmation'
            });
            return;
        }

        track('form_submission_started', {
            venue_id: venue.ID,
            venue_name: venue.Name,
            guest_count: numberOfGuests,
            date_requested: dateRequested ? dateRequested.toISOString() : null
        });

        setSending(true);

        // Calculate pricing estimation
        const defaultSectionId = Object.keys(venue.Sections)[0];
        const pricingEstimate = calculateCheapestVenuePricing(
            venue,
            parseInt(numberOfGuests) || venue.Sections[defaultSectionId].Capacity,
            startTime,
            endTime,
            dateRequested || new Date(),
        );

        // Add semi-private note to message if fully private is not checked
        const semiPrivateNote = !fullyPrivateRequired ? 'Semi-private space is acceptable' : '';

        // Updated combinedMustHaves to include semi-private note
        const combinedMustHaves = [
            openBarRequired ? 'Open Bar' : '',
            ownMusicRequired ? 'Ability to play own music' : '',
            fullyPrivateRequired ? 'Fully Private' : semiPrivateNote,
            otherRequirementsChecked ? mustHaves : '' // Only include mustHaves if Other is checked
        ].filter(Boolean).join(', ');

        try {
            const messageThroughWebsite: CustomerInquiry = {
                VenueID: venue.ID,
                Name: name,
                Email: email,
                Phone: phone,
                Flexibility: flexibility || '',
                LocationPreference: locationPreference || '',
                VenueTypePreference: venueTypePreference || '',
                Message: message,
                MustHaves: combinedMustHaves,
                DateRequested: dateRequested ? dateRequested.toISOString().split('T')[0] : '',
                TimeRequested: `${startTime} - ${endTime}`,
                NumberOfGuests: numberOfGuests,
                InterestedInSimilarVenues: interestedInSimilar,
                CreatedAt: new Date(),
                // Add pricing estimation data
                EstimatedPrice: pricingEstimate.price,
            } as CustomerInquiry;

            const response = await fetch(`${getBackendURL('createMatchmakerSession')}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(messageThroughWebsite)
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to send message');
            }

            // Track successful submission
            track('form_submission_success', {
                venue_id: venue.ID,
                venue_name: venue.Name,
                guest_count: numberOfGuests,
                estimated_price: pricingEstimate.price,
                interested_in_similar: interestedInSimilar
            });

            // Clear modalOpenTime since message was successfully sent
            setModalOpenTime(null);
            setOpen(false);
            setErrors({
                email: '',
                phone: '',
                name: '',
                numberOfGuests: '',
                preferredDate: '',
                preferredTime: ''
            });

            // Show success message
            setShowSuccess(true);
            if (typeof window !== 'undefined' && typeof (window as any).gtag === 'function') {
                console.log('gtag function found');
                (window as any).gtag('event', 'conversion', {
                    'send_to': 'AW-16732071202/gzhwCMm2hqIaEKLKvKo-',
                    'value': 1.0,
                    'currency': 'USD'
                });
                console.log('gtag event sent');
            }

            // Redirect to venue matchmaker page
            router.push(`/venue_matchmaker/${data.matchMakerSessionId}`);

        } catch (error) {
            console.error('Error sending message:', error);
            track('form_submission_error', {
                venue_id: venue.ID,
                venue_name: venue.Name,
                error: error instanceof Error ? error.message : 'Unknown error'
            });
        } finally {
            setSending(false);
        }
    };

    const handleModalOpen = () => {
        if (existingInquiries.length > 0) {
            setShowInquiryChoice(true);
            track('existing_inquiries_modal_opened', {
                venue_id: venue.ID,
                venue_name: venue.Name,
                inquiry_count: existingInquiries.length
            });
        } else {
            setOpen(true);
            setModalOpenTime(new Date());
            track('message_modal_opened', {
                venue_id: venue.ID,
                venue_name: venue.Name || 'Unknown'
            });
            logFirebaseEvent('message_modal_opened', {
                venue_id: venue.ID,
                venue_name: venue.Name || 'Unknown',
                timestamp: new Date().toISOString()
            });
        }
    };

    const handleModalClose = () => {
        setOpen(false);
        // Only log abandonment if modal was opened but message wasn't sent
        if (modalOpenTime) {
            const timeSpentMs = new Date().getTime() - modalOpenTime.getTime();
            track('message_modal_abandoned', {
                venue_id: venue.ID,
                venue_name: venue.Name || 'Unknown',
                time_spent_seconds: Math.round(timeSpentMs / 1000),
                form_step: formStep,
                had_partial_input: Boolean(email || phone || message || name || numberOfGuests)
            });

            logFirebaseEvent('message_modal_abandoned', {
                venue_id: venue.ID,
                venue_name: venue.Name || 'Unknown',
                timestamp: new Date().toISOString(),
                time_spent_seconds: Math.round(timeSpentMs / 1000),
                had_partial_input: Boolean(email || phone || message || name || numberOfGuests)
            });
        }
        setModalOpenTime(null);
    };

    // Add this common TextField styling that we'll reuse
    const commonTextFieldStyles = {
        mb: 2,
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            color: '#F0D1FF',
            borderRadius: '8px',
            fontSize: '16px',
            '& fieldset': {
                borderColor: 'rgba(240, 209, 255, 0.3)',
            },
            '&:hover fieldset': {
                borderColor: 'rgba(240, 209, 255, 0.5)',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#7B5DB2',
            },
            '& input': {
                padding: '12px 14px',
            },
        },
        '& .MuiInputLabel-root': {
            color: '#F0D1FF',
            fontSize: '14px',
            transform: 'translate(14px, 13px) scale(1)',
            '&.Mui-focused, &.MuiFormLabel-filled': {
                transform: 'translate(14px, -9px) scale(0.75)',
            },
        },
        '& .MuiFormHelperText-root': {
            color: '#ff6b6b',
            marginLeft: '2px',
        },
        '& input::placeholder': {
            color: 'rgba(240, 209, 255, 0.5)',
        },
        '& textarea::placeholder': {
            color: 'rgba(240, 209, 255, 0.5)',
        }
    };

    // Generate time options (12-hour format)
    const generateTimeOptions = () => {
        const times = [];
        // Start from 6 AM current day to 6 AM next day
        for (let hour = 6; hour < 30; hour++) {  // 30 = 24 + 6 (next day)
            for (let minute = 0; minute < 60; minute += 60) {
                const actualHour = hour % 24;  // Convert to 24-hour format
                const period = actualHour >= 12 ? 'PM' : 'AM';
                const displayHour = actualHour === 0 ? 12 : actualHour > 12 ? actualHour - 12 : actualHour;
                const displayMinute = minute === 0 ? '00' : minute;
                const nextDay = hour >= 24 ? ' ' : '';
                const time = `${displayHour}:${displayMinute} ${period}${nextDay}`;
                times.push(time);
            }
        }
        return times;
    };

    const timeOptions = generateTimeOptions();

    // Filter end time options based on start time
    const getFilteredEndTimeOptions = () => {
        if (!startTime) return timeOptions;

        const startTimeIndex = timeOptions.indexOf(startTime);
        if (startTimeIndex === -1) return timeOptions;

        return timeOptions.slice(startTimeIndex + 1);
    };

    // Reset end time if it becomes invalid with new start time
    const handleStartTimeChange = (newStartTime: string) => {
        setStartTime(newStartTime);

        // Find the index of both times
        const startIndex = timeOptions.indexOf(newStartTime);
        const endIndex = timeOptions.indexOf(endTime);

        // If end time is before or equal to start time, reset it
        if (endIndex <= startIndex) {
            setEndTime('');
        }
    };

    const formatDate = (dateStr: string) => {
        const [year, month, day] = dateStr.split('-');
        return `${month}/${day}/${year}`;
    };

    const renderExistingInquiriesList = () => {
        const displayedInquiries = showAllInquiries
            ? existingInquiries
            : existingInquiries.slice(0, 3);

        return (
            <>
                <List sx={{ width: '100%', mt: 2 }}>
                    {displayedInquiries.map((inquiry, index) => (
                        <ListItem
                            key={inquiry.ID}
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                borderRadius: '8px',
                                mb: 1,
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}
                        >
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                <Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <EventIcon sx={{ color: '#E0B1F1', mr: 1, fontSize: '1.2rem' }} />
                                        <Typography sx={{ color: '#E0B1F1', fontWeight: 600 }}>
                                            {formatDate(inquiry.InitialDate)}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <GroupIcon sx={{ color: 'rgba(255, 255, 255, 0.7)', mr: 1, fontSize: '1.1rem' }} />
                                        <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '0.9rem' }}>
                                            {inquiry.GuestCount} guests
                                        </Typography>
                                    </Box>
                                </Box>

                                {(() => {
                                    const date = new Date(inquiry.InitialDate);
                                    const price = calculateCheapestVenuePricing(
                                        venue,
                                        inquiry.GuestCount,
                                        inquiry.InitialStartTime,
                                        inquiry.InitialEndTime,
                                        date,
                                    );
                                    return (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 2 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <MonetizationOnIcon sx={{ color: '#E0B1F1', mr: 1, fontSize: '1.2rem' }} />
                                                <Typography sx={{ color: 'rgba(255, 255, 255, 0.85)', fontSize: '0.95rem', fontWeight: 600 }}>
                                                    ${price.price.toLocaleString()}
                                                </Typography>
                                            </Box>
                                            <Typography sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: '0.8rem', mt: 0.5 }}>
                                                Estimated price
                                            </Typography>
                                        </Box>
                                    );
                                })()}

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton
                                        onClick={() => handleDeleteInquiry(inquiry.ID)}
                                        sx={{
                                            color: 'rgba(255, 255, 255, 0.7)',
                                            padding: '8px',
                                            '&:hover': {
                                                color: '#ff6b6b',
                                                backgroundColor: 'rgba(255, 107, 107, 0.1)',
                                            },
                                        }}
                                    >
                                        <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
                                    </IconButton>
                                </Box>
                            </Box>

                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleInquire(inquiry)}
                                    disabled={inquiry.VenueIDsReachedOutTo.includes(venue.ID) || sendingInquiryId === inquiry.ID}
                                    sx={{
                                        backgroundColor: '#7B5DB2',
                                        color: 'white',
                                        minWidth: '80px',
                                        height: '32px',
                                        fontSize: '0.875rem',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: '#9370DB',
                                        },
                                        '&:disabled': {
                                            backgroundColor: 'rgba(123, 93, 178, 0.5)',
                                            color: 'rgba(255, 255, 255, 0.7)',
                                        }
                                    }}
                                >
                                    {inquiry.VenueIDsReachedOutTo.includes(venue.ID) ? 'Sent' :
                                        sendingInquiryId === inquiry.ID ? (
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Box
                                                    component="span"
                                                    sx={{
                                                        width: 16,
                                                        height: 16,
                                                        border: '2px solid rgba(255, 255, 255, 0.3)',
                                                        borderTop: '2px solid white',
                                                        borderRadius: '50%',
                                                        animation: 'spin 1s linear infinite',
                                                        '@keyframes spin': {
                                                            '0%': { transform: 'rotate(0deg)' },
                                                            '100%': { transform: 'rotate(360deg)' },
                                                        },
                                                    }}
                                                />
                                                <span>Sending</span>
                                            </Box>
                                        ) : 'Send'}
                                </Button>
                            </Box>
                        </ListItem>
                    ))}
                </List>

                {existingInquiries.length > 3 && (
                    <Button
                        onClick={() => setShowAllInquiries(!showAllInquiries)}
                        sx={{
                            color: '#F0D1FF',
                            textTransform: 'none',
                            fontSize: '0.9rem',
                            mt: 1,
                            '&:hover': {
                                backgroundColor: 'rgba(240, 209, 255, 0.1)',
                            },
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                        }}
                    >
                        {showAllInquiries ? (
                            'Show Less'
                        ) : (
                            `Show ${existingInquiries.length - 3} More`
                        )}
                        <ExpandMoreIcon
                            sx={{
                                transform: showAllInquiries ? 'rotate(180deg)' : 'none',
                                transition: 'transform 0.2s ease-in-out',
                            }}
                        />
                    </Button>
                )}
            </>
        );
    };

    // Add this new function to handle inquiries
    const handleInquire = async (session: MatchMakerSession) => {
        try {
            if (!session.VenueIDsReachedOutTo.includes(venue.ID)) {
                setSendingInquiryId(session.ID);

                track('existing_inquiry_submission_started', {
                    venue_id: venue.ID,
                    venue_name: venue.Name,
                    session_id: session.ID,
                    guest_count: session.GuestCount
                });

                const requestBody = {
                    matchMakerSessionId: session.ID,
                    venueIds: [venue.ID]
                };

                const response = await fetch(`${getBackendURL('sendMatchmakerInquiry')}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody)
                });

                if (!response.ok) {
                    throw new Error('Failed to send inquiry');
                }

                // Update local state
                const updatedSession = {
                    ...session,
                    VenueIDsReachedOutTo: [...session.VenueIDsReachedOutTo, venue.ID]
                };

                const updatedSessions = existingInquiries.map(s =>
                    s.ID === session.ID ? updatedSession : s
                );

                // Update localStorage and state
                localStorage.setItem('matchmaker_sessions', JSON.stringify(updatedSessions));
                setExistingInquiries(updatedSessions);

                track('existing_inquiry_submission_success', {
                    venue_id: venue.ID,
                    venue_name: venue.Name,
                    session_id: session.ID
                });

                // Show success message
                setShowSuccess(true);
                if (typeof window !== 'undefined' && typeof (window as any).gtag === 'function') {
                    console.log('gtag function found');
                    (window as any).gtag('event', 'conversion', {
                        'send_to': 'AW-16732071202/gzhwCMm2hqIaEKLKvKo-',
                        'value': 1.0,
                        'currency': 'USD'
                    });
                    console.log('gtag event sent');
                }
            }
        } catch (error) {
            console.error('Error sending inquiry:', error);
            track('existing_inquiry_submission_error', {
                venue_id: venue.ID,
                venue_name: venue.Name,
                session_id: session.ID,
                error: error instanceof Error ? error.message : 'Unknown error'
            });
            alert('Failed to send inquiry. Please try again.');
        } finally {
            setSendingInquiryId(null);
        }
    };

    // Add this new function to handle inquiry deletion
    const handleDeleteInquiry = (sessionId: string) => {
        track('inquiry_deleted', {
            venue_id: venue.ID,
            venue_name: venue.Name,
            session_id: sessionId
        });

        const updatedSessions = existingInquiries.filter(session => session.ID !== sessionId);
        localStorage.setItem('matchmaker_sessions', JSON.stringify(updatedSessions));
        setExistingInquiries(updatedSessions);

        // If no more inquiries, close the choice modal and open the regular modal
        if (updatedSessions.length === 0) {
            setShowInquiryChoice(false);
            setOpen(true);
        }
    };

    // Update the Modal content
    const renderInquiryChoiceModal = () => (
        <Modal
            open={showInquiryChoice}
            onClose={() => setShowInquiryChoice(false)}
            closeAfterTransition
        >
            <Fade in={showInquiryChoice}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: '500px' },
                    maxHeight: { xs: '85vh', sm: '90vh' },
                    bgcolor: '#2c2c54',
                    borderRadius: '12px',
                    boxShadow: '0 8px 28px rgba(0,0,0,0.28)',
                    p: 3,
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <IconButton
                        onClick={() => setShowInquiryChoice(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#F0D1FF',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography
                        variant="h6"
                        sx={{
                            color: '#F0D1FF',
                            mb: 1,
                            pr: 4,
                            fontFamily: 'Thick',
                            fontSize: { xs: '1.3rem', sm: '1.5rem' }
                        }}
                    >
                        Existing Inquiries Found
                    </Typography>

                    <Typography
                        sx={{
                            color: '#F0D1FF',
                            mb: 2,
                            opacity: 0.8,
                            fontSize: { xs: '0.85rem', sm: '0.95rem' }
                        }}
                    >
                        Save time by using an existing inquiry - perfect for comparing venues and getting competitive pricing without re-entering your information. Venues can view your requirements and compete to offer you the best deal:
                    </Typography>

                    <Box sx={{
                        flex: 1,
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '6px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: 'rgba(255, 255, 255, 0.05)',
                            borderRadius: '3px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: 'rgba(255, 255, 255, 0.2)',
                            borderRadius: '3px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: 'rgba(255, 255, 255, 0.3)',
                        },
                    }}>
                        {renderExistingInquiriesList()}
                    </Box>

                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => {
                            setShowInquiryChoice(false);
                            setOpen(true);
                        }}
                        sx={{
                            color: '#F0D1FF',
                            borderColor: '#F0D1FF',
                            mt: 3,
                            py: { xs: 1.2, sm: 1.5 },
                            fontSize: { xs: '0.9rem', sm: '1rem' },
                            textTransform: 'none',
                            fontWeight: 500,
                            '&:hover': {
                                borderColor: '#9370DB',
                                backgroundColor: 'rgba(147, 112, 219, 0.1)',
                            },
                        }}
                    >
                        Create New Inquiry
                    </Button>
                </Box>
            </Fade>
        </Modal>
    );

    const handleContinueToConfirmation = (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm()) {
            track('form_validation_failure', {
                venue_id: venue.ID,
                venue_name: venue.Name,
                step: 'contact_information'
            });
            return;
        }

        track('form_step_complete', {
            venue_id: venue.ID,
            venue_name: venue.Name,
            step: 'contact_information',
            has_email: !!email,
            has_phone: !!phone,
            has_must_haves: openBarRequired || ownMusicRequired || fullyPrivateRequired || otherRequirementsChecked
        });

        setFormStep(3);
    };

    // Add confirmation screen content
    const renderConfirmationContent = () => {
        // Calculate pricing
        const defaultSectionId = Object.keys(venue.Sections)[0];
        const pricing = calculateCheapestVenuePricing(
            venue,
            parseInt(numberOfGuests) || venue.Sections[defaultSectionId].Capacity,
            startTime,
            endTime,
            dateRequested || new Date(),
        );

        return (
            <Box sx={{ textAlign: 'left' }}>
                {/* Add prominent pricing display */}
                <Box
                    sx={{
                        backgroundColor: 'rgba(123, 93, 178, 0.15)',
                        borderRadius: '12px',
                        p: 3,
                        mb: 3,
                        border: '1px solid rgba(123, 93, 178, 0.3)',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#F0D1FF',
                            fontSize: '14px',
                            opacity: 0.9,
                            mb: 1,
                        }}
                    >
                        Estimated Cost
                    </Typography>
                    <Typography
                        sx={{
                            color: '#F0D1FF',
                            fontSize: '32px',
                            fontWeight: 700,
                            mb: 1,
                            fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
                        }}
                    >
                        ${pricing.price.toLocaleString()}
                    </Typography>
                    <Typography
                        sx={{
                            color: '#F0D1FF',
                            fontSize: '16px',
                            opacity: 0.8,
                        }}
                    >
                        ${pricing.pricePerPerson.toLocaleString()} per person · {numberOfGuests} guests
                    </Typography>
                </Box>

                <Typography
                    sx={{
                        color: '#F0D1FF',
                        fontSize: '18px',
                        fontWeight: 600,
                        mb: 3,
                        fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
                    }}
                >
                    Before we send your request...
                </Typography>

                <Box sx={{ mb: 1 }}>
                    {[
                        {
                            icon: '💰',
                            title: 'Best Price Guarantee',
                            description: 'Lowest prices guaranteed - we\'ll match any better deal'
                        },
                        {
                            icon: '🤝',
                            title: 'Competitive Pricing',
                            description: 'Venues compete for your business, often offering special deals and promotions exclusive to Litty.'
                        },
                        {
                            icon: '🔒',
                            title: 'Secure Booking',
                            description: 'Protected payments and clear cancellation policies'
                        },
                        {
                            icon: '⭐',
                            title: 'VIP Support',
                            description: 'Priority responses and dedicated booking assistance'
                        }
                    ].map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1.5,
                                bgcolor: 'rgba(255, 255, 255, 0.05)',
                                borderRadius: '8px',
                                p: 1,
                            }}
                        >
                            <Typography sx={{ fontSize: '20px', mr: 1.5 }}>
                                {item.icon}
                            </Typography>
                            <Box>
                                <Typography sx={{ color: '#F0D1FF', fontSize: '14px', fontWeight: 600 }}>
                                    {item.title}
                                </Typography>
                                <Typography sx={{ color: '#F0D1FF', fontSize: '12px', opacity: 0.8 }}>
                                    {item.description}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>

                <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
                    <Button
                        onClick={handleBack}
                        variant="outlined"
                        disabled={sending}
                        sx={{
                            flex: 1,
                            color: '#F0D1FF',
                            borderColor: '#F0D1FF',
                            '&:hover': {
                                borderColor: '#9370DB',
                                backgroundColor: 'rgba(147, 112, 219, 0.1)',
                            },
                            '&:disabled': {
                                borderColor: 'rgba(240, 209, 255, 0.3)',
                                color: 'rgba(240, 209, 255, 0.3)',
                            },
                        }}
                    >
                        Back
                    </Button>

                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={sending || !consentChecked}
                        sx={{
                            flex: 2,
                            backgroundColor: '#7B5DB2',
                            color: 'white',
                            fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
                            py: 1.5,
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 600,
                            textTransform: 'none',
                            borderRadius: '8px',
                            boxShadow: 'none',
                            position: 'relative',
                            '&:hover': {
                                backgroundColor: '#9370DB',
                                transform: 'scale(1.02)',
                                transition: 'transform 0.1s ease-in-out',
                            },
                            '&:disabled': {
                                backgroundColor: '#5A4A84',
                                color: 'rgba(255, 255, 255, 0.7)',
                            },
                        }}
                    >
                        {sending ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box
                                    component="span"
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        border: '2px solid rgba(255, 255, 255, 0.3)',
                                        borderTop: '2px solid white',
                                        borderRadius: '50%',
                                        animation: 'spin 1s linear infinite',
                                        '@keyframes spin': {
                                            '0%': { transform: 'rotate(0deg)' },
                                            '100%': { transform: 'rotate(360deg)' },
                                        },
                                    }}
                                />
                                Sending your request...
                            </Box>
                        ) : (
                            'Send Request'
                        )}
                    </Button>
                </Box>

                {sending && (
                    <Typography
                        sx={{
                            color: '#F0D1FF',
                            fontSize: '14px',
                            textAlign: 'center',
                            mt: 2,
                            opacity: 0.8,
                        }}
                    >
                        Please wait while we contact the venue. This may take up to 10 seconds...
                    </Typography>
                )}
            </Box>
        );
    };

    // Update the form content renderer to include the confirmation step
    const renderFormContent = () => {
        if (formStep === 1) {
            return (
                <>
                    <TextField
                        fullWidth
                        label="Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        error={!!errors.name}
                        helperText={errors.name}
                        required
                        sx={commonTextFieldStyles}
                    />

                    <Accordion
                        expanded={dateAccordionExpanded}
                        onChange={(_, isExpanded) => setDateAccordionExpanded(isExpanded)}
                        sx={{
                            mb: 2,
                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                            color: '#F0D1FF',
                            borderRadius: '8px !important',
                            border: errors.preferredDate ? '1px solid #ff6b6b' : 'none',
                            '&:before': {
                                display: 'none',
                            },
                            '& .MuiAccordionSummary-root': {
                                borderRadius: '8px',
                                backgroundColor: errors.preferredDate ? 'rgba(255, 107, 107, 0.08)' : 'transparent',
                                '&:hover': {
                                    backgroundColor: errors.preferredDate
                                        ? 'rgba(255, 107, 107, 0.12)'
                                        : 'rgba(255, 255, 255, 0.08)',
                                },
                            },
                            '& .MuiAccordionDetails-root': {
                                padding: 2,
                                paddingTop: 0,
                            },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: '#F0D1FF' }} />}
                            sx={{
                                '& .MuiAccordionSummary-content': {
                                    display: 'flex',
                                    flexDirection: 'column',
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#F0D1FF',
                                    fontSize: '14px',
                                    fontFamily: 'Sans',
                                }}
                            >
                                Select Preferred Date*
                            </Typography>
                            {dateRequested && (
                                <Typography
                                    sx={{
                                        color: '#F0D1FF',
                                        opacity: 0.7,
                                        fontSize: '14px',
                                        mt: 0.5,
                                    }}
                                >
                                    {dateRequested.toLocaleDateString()}
                                </Typography>
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                            <SmallCustomCalendar
                                selectedDate={dateRequested || new Date()}
                                onDateChange={(date) => {
                                    setDateRequested(date);
                                    setDateAccordionExpanded(false);
                                    if (errors.preferredDate) {
                                        setErrors(prev => ({ ...prev, preferredDate: '' }));
                                    }
                                }}
                                minDate={new Date()}
                            />
                            {errors.preferredDate && (
                                <Typography
                                    sx={{
                                        color: '#ff6b6b',
                                        fontSize: '12px',
                                        mt: 0.5,
                                        ml: 2
                                    }}
                                >
                                    {errors.preferredDate}
                                </Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>

                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <FormControl fullWidth error={!!errors.preferredTime}>
                            <InputLabel sx={{ color: '#F0D1FF' }}>Start Time*</InputLabel>
                            <Select
                                value={startTime}
                                onChange={(e) => handleStartTimeChange(e.target.value)}
                                required
                                sx={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                    color: '#F0D1FF',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(240, 209, 255, 0.3)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(240, 209, 255, 0.5)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#7B5DB2',
                                    },
                                    '& .MuiSelect-icon': {
                                        color: '#F0D1FF',
                                    },
                                }}
                            >
                                {timeOptions.map((time) => (
                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth error={!!errors.preferredTime}>
                            <InputLabel sx={{ color: '#F0D1FF' }}>End Time*</InputLabel>
                            <Select
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                                required
                                disabled={!startTime}
                                sx={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                    color: '#F0D1FF',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(240, 209, 255, 0.3)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(240, 209, 255, 0.5)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#7B5DB2',
                                    },
                                    '& .MuiSelect-icon': {
                                        color: '#F0D1FF',
                                    },
                                }}
                            >
                                {getFilteredEndTimeOptions().map((time) => (
                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <TextField
                        fullWidth
                        label="Number of Guests"
                        value={numberOfGuests}
                        onChange={(e) => setNumberOfGuests(e.target.value)}
                        placeholder="e.g., 40"
                        required
                        error={!!errors.numberOfGuests}
                        helperText={errors.numberOfGuests}
                        sx={commonTextFieldStyles}
                    />

                    <Button
                        onClick={handleContinue}
                        variant="contained"
                        fullWidth
                        sx={{
                            backgroundColor: '#7B5DB2',
                            color: 'white',
                            fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
                            py: 1.5,
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 600,
                            textTransform: 'none',
                            borderRadius: '8px',
                            boxShadow: 'none',
                            mt: 2,
                            '&:hover': {
                                backgroundColor: '#9370DB',
                                transform: 'scale(1.02)',
                                transition: 'transform 0.1s ease-in-out',
                            },
                        }}
                    >
                        Continue
                    </Button>
                </>
            );
        } else if (formStep === 2) {
            return (
                <>
                    <TextField
                        fullWidth
                        label="Your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!errors.email}
                        helperText={errors.email}
                        sx={commonTextFieldStyles}
                    />

                    <TextField
                        fullWidth
                        label="Your Phone Number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        error={!!errors.phone}
                        helperText={errors.phone}
                        sx={commonTextFieldStyles}
                    />

                    <TextField
                        fullWidth
                        label="Are you flexible with days and times?"
                        value={flexibility}
                        onChange={(e) => setFlexibility(e.target.value)}
                        placeholder="e.g., any day that weekend"
                        sx={commonTextFieldStyles}
                    />

                    <Box sx={{ mb: 2 }}>
                        <Typography
                            sx={{
                                color: '#F0D1FF',
                                fontSize: '14px',
                                mb: 1
                            }}
                        >
                            Must-Have Requirements?
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={openBarRequired}
                                    onChange={(e) => setOpenBarRequired(e.target.checked)}
                                    sx={{
                                        color: '#F0D1FF',
                                        '&.Mui-checked': {
                                            color: '#7B5DB2',
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography sx={{ color: '#F0D1FF', fontSize: '14px' }}>
                                    Open Bar
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={ownMusicRequired}
                                    onChange={(e) => setOwnMusicRequired(e.target.checked)}
                                    sx={{
                                        color: '#F0D1FF',
                                        '&.Mui-checked': {
                                            color: '#7B5DB2',
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography sx={{ color: '#F0D1FF', fontSize: '14px' }}>
                                    Ability to play own music
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={fullyPrivateRequired}
                                    onChange={(e) => setFullyPrivateRequired(e.target.checked)}
                                    sx={{
                                        color: '#F0D1FF',
                                        '&.Mui-checked': {
                                            color: '#7B5DB2',
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography sx={{ color: '#F0D1FF', fontSize: '14px' }}>
                                    Fully Private
                                </Typography>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={otherRequirementsChecked}
                                    onChange={(e) => setOtherRequirementsChecked(e.target.checked)}
                                    sx={{
                                        color: '#F0D1FF',
                                        '&.Mui-checked': {
                                            color: '#7B5DB2',
                                        },
                                    }}
                                />
                            }
                            label={
                                <Typography sx={{ color: '#F0D1FF', fontSize: '14px' }}>
                                    Other
                                </Typography>
                            }
                        />

                        {otherRequirementsChecked && (
                            <TextField
                                fullWidth
                                label="Other Must-Have Requirements"
                                value={mustHaves}
                                onChange={(e) => setMustHaves(e.target.value)}
                                multiline
                                rows={1}
                                placeholder="Any other specific requirements?"
                                sx={commonTextFieldStyles}
                            />
                        )}
                    </Box>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={interestedInSimilar}
                                onChange={(e) => setInterestedInSimilar(e.target.checked)}
                                sx={{
                                    color: '#F0D1FF',
                                    '&.Mui-checked': {
                                        color: '#7B5DB2',
                                    },
                                }}
                            />
                        }
                        label={
                            <Typography sx={{ color: '#F0D1FF', fontSize: '14px' }}>
                                I'm interested in similar venues
                            </Typography>
                        }
                        sx={{ mb: 2 }}
                    />

                    {interestedInSimilar && (
                        <>
                            <TextField
                                fullWidth
                                label="Location or Neighborhood Preference"
                                value={locationPreference}
                                onChange={(e) => setLocationPreference(e.target.value)}
                                sx={commonTextFieldStyles}
                            />

                            <TextField
                                fullWidth
                                label="Preference (Bars, Restaurants, Raw Spaces, ...)"
                                value={venueTypePreference}
                                onChange={(e) => setVenueTypePreference(e.target.value)}
                                sx={commonTextFieldStyles}
                            />
                        </>
                    )}

                    <TextField
                        fullWidth
                        label="Additional Message (Optional)"
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value);
                        }}
                        multiline
                        rows={2}
                        placeholder={`Hello I'm interested about this space for ${dateRequested ? new Date(dateRequested).toLocaleDateString() : 'a date'}, for 40 people`}
                        sx={{
                            ...commonTextFieldStyles,
                            '& .MuiOutlinedInput-root': {
                                ...commonTextFieldStyles['& .MuiOutlinedInput-root'],
                                '& textarea': {
                                    padding: '8px 14px',
                                },
                            }
                        }}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={consentChecked}
                                onChange={(e) => setConsentChecked(e.target.checked)}
                                sx={{
                                    color: '#F0D1FF',
                                    '&.Mui-checked': {
                                        color: '#7B5DB2',
                                    },
                                }}
                            />
                        }
                        label={
                            <Typography sx={{ color: '#F0D1FF', fontSize: '14px' }}>
                                I agree to receive text and/or email messages from Litty related to this inquiry and understand that message and data rates may apply. You can opt out at any time. Litty will not share your information with any third parties.
                            </Typography>
                        }
                        sx={{ mb: 2 }}
                    />

                    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                        <Button
                            onClick={handleBack}
                            variant="outlined"
                            sx={{
                                flex: 1,
                                color: '#F0D1FF',
                                borderColor: '#F0D1FF',
                                '&:hover': {
                                    borderColor: '#9370DB',
                                    backgroundColor: 'rgba(147, 112, 219, 0.1)',
                                },
                            }}
                        >
                            Back
                        </Button>

                        <Button
                            onClick={handleContinueToConfirmation}
                            variant="contained"
                            disabled={sending || !consentChecked}
                            sx={{
                                flex: 2,
                                backgroundColor: consentChecked ? '#7B5DB2' : '#5A4A84',
                                color: 'white',
                                fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
                                py: 1.5,
                                fontSize: { xs: '14px', sm: '16px' },
                                fontWeight: 600,
                                textTransform: 'none',
                                borderRadius: '8px',
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: consentChecked ? '#9370DB' : '#5A4A84',
                                    transform: consentChecked ? 'scale(1.02)' : 'none',
                                    transition: 'transform 0.1s ease-in-out',
                                },
                                '&:disabled': {
                                    backgroundColor: '#5A4A84',
                                    color: 'rgba(255, 255, 255, 0.7)',
                                },
                            }}
                        >
                            Continue
                        </Button>
                    </Box>
                </>
            );
        } else {
            return renderConfirmationContent();
        }
    };

    // Update modal title and description
    const getModalTitleAndDescription = () => {
        switch (formStep) {
            case 1:
                return {
                    title: 'Event Details',
                    description: 'Please provide the basic details about your event'
                };
            case 2:
                return {
                    title: 'Contact Information',
                    description: 'How can the venue owner reach you?'
                };
            case 3:
                return {
                    title: 'Confirm Your Request',
                    description: 'Review your details before we send your request'
                };
            default:
                return { title: '', description: '' };
        }
    };

    return (
        <>
            {showMatchmaker ? (
                <InquireToVenueMatchmaker
                    venue={venue}
                />
            ) : (
                <Button
                    variant="contained"
                    onClick={handleModalOpen}
                    sx={{
                        backgroundColor: '#7B5DB2',
                        color: 'white',
                        fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
                        py: 2,
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: 600,
                        textTransform: 'none',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: '#9370DB',
                            transform: 'scale(1.02)',
                            transition: 'transform 0.1s ease-in-out',
                        },
                    }}
                >
                    Message Venue Owner
                </Button>
            )}

            <Modal
                open={open}
                onClose={handleModalClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box sx={{
                        position: 'absolute',
                        top: { xs: 0, sm: '50%' },
                        left: { xs: 0, sm: '50%' },
                        transform: { xs: 'none', sm: 'translate(-50%, -50%)' },
                        width: { xs: '100%', sm: '500px' },
                        height: { xs: '100%', sm: 'auto' },
                        maxHeight: { xs: '100%', sm: '95vh' },
                        overflow: 'auto',
                        bgcolor: '#2c2c54',
                        borderRadius: { xs: 0, sm: '12px' },
                        boxShadow: '0 8px 28px rgba(0,0,0,0.28)',
                        p: { xs: 2, sm: 3 },
                        border: 'none',
                    }}>
                        <IconButton
                            onClick={handleModalClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: '#F0D1FF',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                                color: '#F0D1FF',
                                mb: 0.5,
                                mt: 1.5,
                                fontSize: { xs: '20px', sm: '22px' },
                                fontWeight: 600,
                                fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
                            }}
                        >
                            {getModalTitleAndDescription().title}
                        </Typography>

                        <Typography
                            variant="body1"
                            sx={{
                                color: '#F0D1FF',
                                mb: 3,
                                opacity: 0.8,
                                fontFamily: 'Sans',
                            }}
                        >
                            {getModalTitleAndDescription().description}
                        </Typography>

                        <form onSubmit={formStep === 1 ? handleContinue : handleSubmit}>
                            {renderFormContent()}
                        </form>
                    </Box>
                </Fade>
            </Modal>

            {renderInquiryChoiceModal()}

            <Snackbar
                open={showSuccess}
                autoHideDuration={15000}
                onClose={() => setShowSuccess(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setShowSuccess(false)}
                    severity="success"
                    icon={<CheckCircleOutlineIcon fontSize="inherit" />}
                    sx={{
                        width: '100%',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        borderRadius: '8px',
                        '& .MuiAlert-icon': {
                            color: 'white'
                        },
                        fontFamily: 'Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
                        fontSize: '16px',
                        alignItems: 'center',
                        boxShadow: '0 8px 28px rgba(0,0,0,0.28)',
                        py: 1.5,
                    }}
                >
                    Message sent successfully! The venue owner will contact you shortly.
                </Alert>
            </Snackbar>
        </>
    );
};

export default MessageThroughWebsite;



